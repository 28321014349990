import CommonHelper from '../common-helper';
import SignupPayment from './signup-payment';
import Swal from '../sweetalert2';
import i18n from '../i18n';
import mixpanel from 'mixpanel-browser';
import { shop as SHOP_API } from '@teem-app/shop/constants/api';
import * as Api from '../constants/api';
import HomeSignupModule from './home-signup';
import HomeFreelanceSignupModule from './home-freelance-signup';
import PricingModule from './pricing';
import { verifyPromocode } from './teem-helper';

var SignupModule = (function (commonHelper) {
	'use strict';
	let selectedCity = null;
	var mdl = {};

	mdl.urls = {
		unique_user_email: Api.user.unique_email,
		unique_user_phone: Api.user.unique_phone,
		verify_promocode: Api.promocode.verify,
		register: Api.auth.register,
	}
	mdl.signupSteps = {};

	mdl.signupStep1Validator = null;
	mdl.signupStep2Validator = null;
	mdl.signupStep3Validator = null;
	mdl.selectedPlan = null;

	mdl.defaultValidationOptions = {
		ignore: [],
		unhighlight: function (element) {
			$(element).closest('fieldset').removeClass("has-error");
			$(element).siblings('.field-status').removeClass('in-valid');
		},
		errorPlacement: function (error, element) {
			$(element).siblings('.field-status').removeClass('valid').addClass('in-valid');
			$(element).siblings('.field-error').text(error.text());
		},
		success: function (error, element) {
			$(element).closest('fieldset').removeClass("has-error");
			$(element).siblings('.field-status').removeClass('in-valid').addClass('valid');
		}
	}

	mdl.signupStep1ValidationOptions = {
		rules: {
			name: {
				required: true,
				regex: /^[0-9a-zA-Z\u0600-\u06FF ]*$/
			},
			business_email: {
				required: true,
				regex: /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
				remote: {
					url: mdl.urls.unique_user_email,
					data: {
						email: function () {
							return mdl.selectors.signupStep1.find('input[name="business_email"]').val()
						}
					},
					dataFilter: function (response) {
						var data = JSON.parse(response);
						return !data.is_exist;
					}
				}
			},
			business_phone: {
				required: true,
				number: true,
				remote: {
					url: mdl.urls.unique_user_phone,
					data: {
						phone: function () {
							return mdl.selectors.signupStep1.find('input[name="business_phone"]').val()
						}
					},
					dataFilter: function (response) {
						var data = JSON.parse(response);
						return !data.is_exist;
					}
				}
			},
			country: 'required',
			city_id: {
				required: function () {
					return ($("#country_id option:selected").val() == "682");
				}
			},
			city: {
				required: function () {
					return ($("#country_id option:selected").val() != "682");
				}
			},
			password: {
				required: true,
				minlength: 6
			},
			password_confirm: {
				minlength: 6,
				equalTo: "#businessPassword"
			}
		},
		messages: {
			business_email: {
				remote: i18n.t('front_pages.sign_up_page.validation.email_exist')
			},
			business_phone: {
				remote: i18n.t('front_pages.sign_up_page.validation.phone_exist')
			}
		},
		submitHandler: function (form) {
			try {
				if(mdl.experimentId > 0 && mdl.groupId != 1) {
					mdl.completeStep('step3', form);

				} else {
					mdl.completeStep('step1', form);
				}
			} catch (err) {
				console.error(err)
			}
			return false;
		}
	};

	mdl.signupStep2ValidationOptions = {
		rules: {			
			business_name: 'required',
			company_type: 'required',
			size_of_team: {number: true},
		},
		submitHandler: function (form) {
			try {
				mdl.completeStep('step2', form);
			} catch (err) {
				console.error(err)
			}
			return false;
		}
	};

	mdl.signupStep3ValidationOptions = {
		// rules: {
		// 	'reference_from[]': {
		// 		'required': true,
		// 		'minlength': 1
		// 	}
		// },
		submitHandler: function (form) {
			try {
				let fd = CommonHelper.getFormData(form);

				if (!fd['reference_from[]'] || fd['reference_from[]'].length == 0) {
					Swal.fire(i18n.t('front_pages.error'), i18n.t('front_pages.sign_up_page.validation.please_select_atleast_one'), 'warning');
				} else {
					mdl.completeStep('step3', form);
				}
			} catch (err) {
				console.error(err)
			}
			return false;
		}
	}

	mdl.select2Init = function () {
		$("#select2City").select2({
			'minimumInputLength': 3,
			language: {
				inputTooShort: function (args) {
					return "Type city name";
				}
			},
			ajax: {
				url: SHOP_API.getCities,
				type: "GET",
				dataType: 'json',
				delay: 250,
				data: function (params) {
					return {
						q: params.term // search term
					};
				},
				processResults: formatSelect2Data,
				cache: true
			}
		}).on('select2:select', (event) => {
			selectedCity = event.params.data;
		});
	}

	let formatSelect2Data = function (response) {
		return {
			results: $.map(response, function (item) {
				return {
					text: item.name,
					name: item.name,
					id: item.id
				}
			})
		};
	}

	mdl.init = function () {
		mdl.homesignupStep = {
			key: 'homesignup',
			data: null,
			completed: false
		}
		mdl.signupSteps = {
			'step1': {
				key: 'step1',
				container: $('.setup-content#step1'),
				formSelector: $('.setup-content#step1 form'),
				stepLink: $('.step-items[data-target="step1"]'),
				data: null,
				completed: false
			},
			'step2': {
				key: 'step2',
				container: $('.setup-content#step2'),
				formSelector: $('.setup-content#step2 form'),
				stepLink: $('.step-items[data-target="step2"]'),
				data: null,
				completed: false
			},
			'step3': {
				key: 'step3',
				container: $('.setup-content#step3'),
				formSelector: $('.setup-content#step3 form'),
				stepLink: $('.step-items[data-target="step3"]'),
				data: null,
				completed: false
			}
		}
		mdl.experimentId = $('#experiment_id').val();
		mdl.groupId = $('#group_id').val();
		// alert(mdl.experimentId)

		mdl.bindSelectors();
		mdl.bindListeners();
		mdl.bindFormValidations();

		mdl.selectors.allSteps.hide();
		mdl.gotoStep('step1');

		mdl.signupSteps.step1.data = null;
		mdl.signupSteps.step2.data = null;
		mdl.signupSteps.step3.data = null;

		mdl.signupSteps.step1.formSelector[0].reset();
		console.log(mdl)
		if(mdl.signupSteps.step2.length) {
			mdl.signupSteps.step2.formSelector[0].reset();
		}
		if(mdl.signupSteps.step2.length) {

			mdl.signupSteps.step3.formSelector[0].reset();
		}

		$('select').niceSelect('update');
		$('.field-status').removeClass('valid');

		// $('input', mdl.selectors.signupWizard).attr('autocomplete', 'off');

		$('#business-phone, #size_of_team').keypress(function (e) {
			var charCode = (e.which) ? e.which : event.keyCode;
			if (String.fromCharCode(charCode).match(/[^0-9]/g))
				return false;
		});   

		$('.hear-about').click(function(e){	
			e.preventDefault;
			$(this).toggleClass('active');
			$(this).removeClass('focus');
			let checkBoxes = $(this).find("input[name=reference_from\\[\\]]");
			checkBoxes.attr("checked", !checkBoxes.attr("checked"));
		})

		mdl.select2Init();
		mdl.initializeHomeSignup();
		mdl.initializePricingModule();
		mdl.initializeFromUrlParams();
	}

	mdl.initializeHomeSignup = function () {
		if ($('#freelance_signup_enabled').val()) {
			console.log('new')
			mdl.homeSignupModule = HomeFreelanceSignupModule;
		} else {
			mdl.homeSignupModule = HomeSignupModule;
		}

		mdl.homeSignupModule.init();

		mdl.homeSignupModule.onSubmit = mdl.completeHomeSignupStep;
	}

	mdl.initializePricingModule = function () {
		// If current page is pricing page then intilize pricing module
		if ($('#main.pricing')) {
			PricingModule.init();

			PricingModule.onSelectPlan = function (data) {
				mdl.selectedPlan = data;
				mdl.showSignupForm();
			};
		}
	}

	mdl.completeHomeSignupStep = function (fd) {
		mdl.homesignupStep.data = fd;
		mdl.homesignupStep.completed = true;

		mdl.signupSteps.step1.data = {
			name: fd.name,
			business_email: fd.email,
			business_phone: fd.phone
		}
		mdl.signupSteps.step2.data = {
			//
		}

		mdl.showSignupForm();
	}

	mdl.completeStep = function (stepKey, form) {
		mixpanel.init(MixpanelToken);
		if(stepKey == 'step1') {
			// mixpanel.init(MixpanelToken);
			mixpanel.track('auth_signup_page1_submit_click');
		}

		if(stepKey == 'step2') {

			// mixpanel.init(MixpanelToken);
			mixpanel.track('auth_signup_page2_submit_click');
		}

		if(stepKey == 'step3') {
			if(mdl.experimentId > 0 && mdl.groupId != 1) {
				mixpanel.track('auth_signup_page1_submit_click');
			} else {
				mixpanel.track('auth_signup_page3_submit_click');
			}
		}

		var steps = Object.values(mdl.signupSteps);
		var step = steps.find(function (s) { return s.key == stepKey });

		step.completed = true;
		step.data = commonHelper.getFormData(form);

		var currentIndex = steps.findIndex(function (s) { return s.key == step.key });

		if (steps[currentIndex + 1]) {
			mdl.gotoStep(steps[currentIndex + 1].key);
		} else {
			if (mdl.selectedPlan && Object.keys(mdl.selectedPlan).length > 0) {
				mdl.startPayment();
			} else {
				mdl.registerUser();
			}
		}
	}

	mdl.startPayment = function () {
		var fd = {};

		// Merge home signup form data
		if (mdl.homesignupStep.completed && mdl.homesignupStep.data) {
			let homeSignupData = mdl.homesignupStep.data;

			fd.email = homeSignupData.email;

			// new signup form data
			if (homeSignupData.phone || homeSignupData.coupon_detail) {
				fd.phone = homeSignupData.phone;
				if (homeSignupData.coupon_code || homeSignupData.coupon_detail) {
					fd.coupon_detail = homeSignupData.coupon_detail;
				}
				if (mdl.coupon_detail) {
					fd.coupon_detail = mdl.coupon_detail;
				}
			}
		} else if (mdl.coupon_detail) {
			fd.coupon_detail = mdl.coupon_detail;
		}

		Object.values(mdl.signupSteps).forEach(function (step) {
			if (step.data) {
				Object.assign(fd, step.data);
			}
		});

		if (mdl.selectedPlan) {
			if (mdl.selectedPlan.coupon_detail)
				fd.coupon_detail = mdl.selectedPlan.coupon_detail;

			delete mdl.selectedPlan.coupon_detail;

			fd.selectedPlan = mdl.selectedPlan;
		}

		mdl.selectors.signupWizard.addClass('d-none');
		mdl.selectors.mainPageContent.removeClass('d-none').find('#main').addClass('d-none');
		mdl.selectors.signupPaymentPage.removeClass('d-none');
		SignupPayment.init(fd);
	}

	mdl.registerUser = function () {
		CommonHelper.showLoading(true);
		try {
			var fd = {};

			// Merge home signup form data
			if (mdl.homesignupStep.completed && mdl.homesignupStep.data) {
				let homeSignupData = mdl.homesignupStep.data;

				fd.email = homeSignupData.email;
				fd.phone = homeSignupData.phone;
				if (homeSignupData.coupon_code || homeSignupData.coupon_detail) {
					fd.coupon_detail = homeSignupData.coupon_detail;
				}
			} else if (mdl.coupon_detail) {
				fd.coupon_detail = mdl.coupon_detail;
			}

			// Merge all signup steps
			Object.values(mdl.signupSteps).forEach(function (step) {
				if (step.data) {
					Object.assign(fd, step.data);
				}
			});

			if (!fd.email) {
				fd.email = fd.business_email;
			}

			if (!fd.phone) {
				fd.phone = fd.business_phone;
			}

			if (fd.other_company_type) {
				fd.company_type = fd.other_company_type;
			}

			if (fd.country_id == 'other')
				fd.country_id = null;

			// Send the token to your server
			$.ajax({
				url: mdl.urls.register,
				type: "post",
				data: fd,
				success: function (response) {
					woopra.track("signup", { email: fd.email, name: fd.name, company: fd.business_name });
					// Identify customer
					woopra.identify({ email: fd.email, name: fd.name, company: fd.business_name });
					woopra.track();
					
					if (response.user_id) {
						// Mixpanel
						mixpanel.init(MixpanelToken);
						mixpanel.identify(response.user_id);
					}

					CommonHelper.showLoading(false);
					if (response.otp_verify) {
						window.location.href = response.otp_verify
					} else {
						window.location.href = "/verify-email"
					}
				},
				error: function (xhr) {
					CommonHelper.showLoading(false);

					var error = xhr.responseJSON;

					Swal.fire({
						title: i18n.t("front_pages.warning"),
						text: error.message ? i18n.t(error.message, i18n.t('front_pages.request_failed')) : i18n.t('front_pages.request_failed'),
						allowOutsideClick: () => !Swal.isLoading(),
						icon: "warning",
						confirmButtonText: i18n.t("front_pages.ok", "Ok")
					});
				}
			});
		} catch (err) {
			console.error(err);
			CommonHelper.showLoading(false);
		}
	}

	mdl.initializeStepForm = function (step) {
		Object.keys(step.data).forEach(function (key) {
			if ($('input[name="' + key + '"]', step.formSelector).length) {
				$('input[name="' + key + '"]', step.formSelector).val(step.data[key])
			}
		})
	}

	mdl.gotoStep = function (stepKey) {
		// find setp by key
		var step = mdl.signupSteps[stepKey];

		if(stepKey == 'step1') {
			mixpanel.init(MixpanelToken);
			mixpanel.track('auth_signup_page1_view');
		}

		if(stepKey == 'step2') {
			mixpanel.init(MixpanelToken);
			mixpanel.track('auth_signup_page2_view');
		}

		if(stepKey == 'step3') {
			mixpanel.init(MixpanelToken);
			mixpanel.track('auth_signup_page3_view');
		}

		if (step) {

			var steps = Object.values(mdl.signupSteps);
			var index = steps.indexOf(step);
			var i;

			if (step.data) {
				mdl.initializeStepForm(step);
			}

			// Check all previous steps are completed
			for (i = 0; i < index; i++) {
				if (!steps[i].completed) {
					return;
				}
			}

			// Hide all steps and show this step
			mdl.selectors.allSteps.hide();
			step.container.show();

			// Add active class to links which trigge this step
			mdl.selectors.stepLinks.removeClass('active').addClass('step-items');
			step.stepLink.addClass('active');

			// Focus on first input
			step.formSelector.find('input:eq(0)').not('[type="checkbox"]').focus();
		}
	}

	mdl.bindSelectors = function () {
		mdl.selectors = {
			signupPaymentPage: $('#signup-payment'),
			homeSignupForm: $('#home-signup-form'),
			allSteps: $('.setup-content'),
			signupStep1: $('.setup-content#step1'),
			signupStep2: $('.setup-content#step2'),
			signupStep3: $('.setup-content#step3'),
			stepLinks: $('.step-items'),
			triggerSignup: $('.trigger-signup'),
			triggerSignin: $('.trigger-signin'),
			mainPageContent: $('#page-content'),
			signupWizard: $('#signup-wizard'),
			togglePassword: $(".toggle-password"),
			passwordInput: $("#businessPassword"),
			storeTypeInput: $("#store_type"),			
			cityDropDown: $("#city_id"),
			cityInput: $("#city"),
			companyTypeInput: $("#ompany_type"),
			otherCompanyTypeInput: $("#other_company_type")
		}
	}

	mdl.bindFormValidations = function () {
		$('#business-email').blur(function () {
			mixpanel.init(MixpanelToken);
			mixpanel.track('auth_signup_start');
		});
		
		if (mdl.selectors.signupStep1)
			mdl.signupStep1Validator = $('form', mdl.selectors.signupStep1).validate(Object.assign(mdl.defaultValidationOptions, mdl.signupStep1ValidationOptions));

		if (mdl.selectors.signupStep2)
			mdl.signupStep2Validator = $('form', mdl.selectors.signupStep2).validate(Object.assign(mdl.defaultValidationOptions, mdl.signupStep2ValidationOptions));

		if (mdl.selectors.signupStep3)
			mdl.signupStep3Validator = $('form', mdl.selectors.signupStep3).validate(Object.assign(mdl.defaultValidationOptions, mdl.signupStep3ValidationOptions));
	}

	mdl.bindListeners = function () {
		$('select', mdl.selectors.signupStep1).change(function () {
			mdl.signupStep1Validator.element($(this));
			if ($(this).attr('id') == 'country_id') {
				$('#country_code', mdl.selectors.signupStep1).val($('option:selected', $(this)).data('code'));
				let callingCode = $('option:selected', $(this)).data('callingcode');
				$('#countryCode').val(callingCode);
				mdl.selectors.cityInput.val('');
				mdl.selectors.cityDropDown.val(null).trigger('change');

				if ($('#country_id').val() != '682') {
					mdl.selectors.cityInput.parents('fieldset').removeClass('d-none');
					mdl.selectors.cityDropDown.parents('fieldset').addClass('d-none');
				}

				if ($('#country_id').val() == '682') {
					mdl.selectors.cityInput.parents('fieldset').addClass('d-none');
					mdl.selectors.cityDropDown.parents('fieldset').removeClass('d-none');
				}
			}
		});

		$('select', mdl.selectors.signupStep2).change(function () {
			mdl.signupStep2Validator.element($(this));			
			if ($(this).attr('id') == 'company_type') {
				mdl.selectors.otherCompanyTypeInput.val('');
				if ($('#company_type').val() == 'Others') {
					mdl.selectors.otherCompanyTypeInput.parents('fieldset').removeClass('d-none');
					mdl.selectors.otherCompanyTypeInput.attr("required", "true");
					mdl.selectors.storeTypeInput.parents('div').addClass('margin-unset');
				}

				if ($('#company_type').val() != 'Others') {
					mdl.selectors.otherCompanyTypeInput.parents('fieldset').addClass('d-none');
					mdl.selectors.otherCompanyTypeInput.removeAttr("required");
					mdl.selectors.storeTypeInput.parents('div').removeClass('margin-unset');
				}
			}
		});

		$(mdl.selectors.cityDropDown).change(function () {
			mdl.selectors.cityInput.val(mdl.selectors.cityDropDown.parents('fieldset').find("li.selected").text());
		})

		mdl.selectors.stepLinks.click(function (e) {
			e.preventDefault();
			var $target = $(this).data('target');
			mdl.gotoStep($target);
		});

		mdl.selectors.triggerSignup.click(function () {
			if (mdl.selectors.mainPageContent.find('#main').hasClass('d-none'))
				return false;

			mdl.selectedPlan = null;

			mdl.signupSteps.step1.data = null;
			mdl.signupSteps.step2.data = null;
			mdl.signupSteps.step3.data = null;

			mdl.homesignupStep.data = null;

			mdl.showSignupForm();


		});

		mdl.showSignupForm = function () {

			// Reset home signup form

			if (mdl.homeSignupModule.homeSignupValidator)
				mdl.homeSignupModule.homeSignupValidator.resetForm();

			mdl.signupSteps.step1.formSelector[0].reset();
			mdl.signupSteps.step2.formSelector[0].reset();
			mdl.signupSteps.step3.formSelector[0].reset();

			$('select').niceSelect('update');
			$('.field-status').removeClass('valid');

			mdl.selectors.mainPageContent.addClass('d-none');
			mdl.selectors.signupWizard.removeClass('d-none');

			mdl.gotoStep('step1');

		}

		mdl.selectors.triggerSignin.click(function () {
			// Reset Signup steps
			mdl.signupStep1Validator.resetForm();
			mdl.signupStep2Validator.resetForm();
			mdl.signupStep3Validator.resetForm();

			mdl.signupSteps.step1.formSelector[0].reset();
			mdl.signupSteps.step2.formSelector[0].reset();
			mdl.signupSteps.step3.formSelector[0].reset();

			mdl.signupSteps.step1.data = null;
			mdl.signupSteps.step2.data = null;
			mdl.signupSteps.step3.data = null;

			$('select').niceSelect('update');
			$('.field-status').removeClass('valid');

			mdl.selectors.mainPageContent.removeClass('d-none');
			mdl.selectors.signupWizard.addClass('d-none');
		});

		mdl.selectors.togglePassword.click(function () {
			var $pwd = mdl.selectors.passwordInput;

			if ($pwd.attr('type') === 'password') {
				$pwd.attr('type', 'text');
				$(".eye-icon").addClass('active');
				$(".hide-password").addClass('d-none');
				$(".show-password").removeClass('d-none');
			} else {
				$pwd.attr('type', 'password');
				$(".eye-icon").removeClass('active');
				$(".hide-password").removeClass('d-none');
				$(".show-password").addClass('d-none');
			}
		});
	}

	mdl.initializeFromUrlParams = function () {
		var params = new window.URLSearchParams(window.location.search);

		if (params.has('name') && $('#name').length > 0) {
			$('#name').val(params.get('name'));
		}

		if (params.has('email') && $('#email').length > 0) {
			$('#business-email').val(params.get('email'));
		}

		if (params.has('phoneNumber') && $('#business-phone').length > 0) {
			$('#business-phone').val(params.get('phoneNumber'));
		}

		if (params.has('plantype') && params.has('frequency')) {
			mdl.selectedPlan = {};

			if (params.has('plantype'))
				mdl.selectedPlan.plan_preference = params.get('plantype');

			if (params.has('frequency'))
				mdl.selectedPlan.plan_frequency = params.get('frequency');
		}

		// name=Umeed+Emad&authorizedID=35eec804&phoneNumber=00792600579&email=info%40umeedemad.com

		if (params.has('authorizedID')) {
			console.log('coupon code');
			mdl.fetchFreelanceCouponDetail(params.get('authorizedID'));
		} else if (params.has('promocode')) {
			console.log('coupon code');
			mdl.fetchFreelanceCouponDetail(params.get('promocode'), false);
		}

	}

	mdl.fetchFreelanceCouponDetail = function (documentId, type = true) {
		if (documentId) {
			CommonHelper.showLoading(true);
			
			let param = {};
			if (type) {
				param = { coupon_code: documentId, 'is_freelance': type };
			} else {
				let plan = mdl.selectedPlan.plan_preference +'-'+ mdl.selectedPlan.plan_frequency;
				param = { coupon_code: documentId, 'is_freelance': type, subscription_plan: plan };
			}			
			
			verifyPromocode(param).then(function (promoCode) {
				CommonHelper.showLoading(false);
				mdl.coupon_detail = promoCode;
			}).catch(function (err) {
				CommonHelper.showLoading(false);
			})
		}
	}
	return mdl;
})(CommonHelper);

export default SignupModule;
