import { verifyPromocode } from './teem-helper';
import CommonHelper from '../common-helper';
import i18n from  '../i18n';

var pricingModule = (function(){
	var mdl = {};
	mdl.planFormData = {
		basic_monthly : {},
		basic_yearly : {},
		pro_monthly : {},
		pro_yearly : {}
	};

	mdl.promocodeValidationOptions ={
		rules: {
			coupon_code: 'required'
		},
		submitHandler: function(form) {
			let fd = CommonHelper.getFormData(form);
			let validator = this;

			var planType = $(form).find('input[name="plan_type"]').val();
			var planFrequency = $(form).find('input[name="plan_frequency"]').val();
			var planFormKey = planType+"_"+planFrequency

			var subscriptionPlan = `${planType}-${planFrequency}`;

			CommonHelper.showLoading(true);
			verifyPromocode({
				coupon_code: fd.coupon_code, 
				subscription_plan:subscriptionPlan,
				is_freelance: false
			}).then(function(couponDetail) {
				mdl.planFormData[planFormKey].couponDetail = couponDetail;

				// Reset promocode form
				validator.resetForm();
				$(form)[0].reset();

				// Show coupon detail
				mdl.updateCouponDOMElements(form);

				CommonHelper.showLoading(false);
			}).catch(function(err) {
				CommonHelper.showLoading(false);

				mdl.planFormData[planFormKey].couponDetail = null;

				if(err && err.message)
					validator.showErrors({'coupon_code': err.message});
				else
					validator.showErrors({'coupon_code': i18n.t('front_pages.request_failed')});
			});
		}
	}

	mdl.init = function() {
		mdl.bindSelectors();
		mdl.bindFormValidations();
		mdl.bindListeners();
	}

	mdl.updateCouponDOMElements = function(promocodeForm) {
		var $promocodeForm = $(promocodeForm);
		var $appliedCouponArea = $promocodeForm.siblings('.account-setting__plan--promo-apply');

		var planType = $(promocodeForm).find('input[name="plan_type"]').val();
		var planFrequency = $(promocodeForm).find('input[name="plan_frequency"]').val();
		var planFormKey = planType+"_"+planFrequency
		var couponDetail = mdl.planFormData[planFormKey].couponDetail;

		var couponDomElements = {
			appliedCouponDetail: $('#pricing--coupon-detail', $appliedCouponArea),
			appliedCouponFreelance: $('#pricing--coupon-freelance', $appliedCouponArea),
			appliedCouponCodeText: $('#pricing--applied_coupon_code', $appliedCouponArea),
			appliedCouponValueText: $('#pricing--applied_coupon_value', $appliedCouponArea),
			appliedCouponValueTypeText: $('#pricing--applied_coupon_value_type', $appliedCouponArea),	
			appliedCouponDiscountMessage: $('#pricing--discount-message', $appliedCouponArea),
			cancelCouponUpdate: $('.pricing--cancel-coupon', $appliedCouponArea) 
		}

		if(couponDetail) {
			$promocodeForm.addClass('d-none');
			$appliedCouponArea.removeClass('d-none');
			
			if(couponDetail.freelance_document_id) {
				couponDomElements.appliedCouponDetail.addClass('d-none');
				couponDomElements.appliedCouponFreelance.removeClass('d-none');
			} else {
				couponDomElements.appliedCouponDetail.removeClass('d-none');
				couponDomElements.appliedCouponFreelance.addClass('d-none');

				couponDomElements.appliedCouponCodeText.text(couponDetail.coupon_code)
				couponDomElements.appliedCouponValueText.text(couponDetail.coupon_value)
				couponDomElements.appliedCouponValueTypeText.text(i18n.t('front_pages.percentage','Percentage'));	
				couponDomElements.appliedCouponDiscountMessage.text(couponDetail.discount_message);
			}

			couponDomElements.cancelCouponUpdate.one('click', function() {
				mdl.planFormData[planFormKey].couponDetail = null;
				mdl.updateCouponDOMElements(promocodeForm);
			})
		}	else {
			$promocodeForm.removeClass('d-none');
			$appliedCouponArea.addClass('d-none');
			
			couponDomElements.appliedCouponCodeText.text('');
			couponDomElements.appliedCouponValueText.text('');
			couponDomElements.appliedCouponValueTypeText.text('');
			couponDomElements.appliedCouponDiscountMessage.text('');
		}
	}

	mdl.bindSelectors = function() {
		mdl.selectors = {
			promocodeForms: $('#main.pricing .account-setting__plan--promo'),
			selectPlan: $('.select-plan')
		}
	}

	mdl.bindFormValidations = function() {
		mdl.selectors.promocodeForms.each(function() {
			$(this).validate(mdl.promocodeValidationOptions);
		});
	}

	mdl.bindListeners = function() {
		mdl.selectors.selectPlan.click(function() {
			let fd = {};

			fd.plan_preference = $(this).data('plan');
			fd.plan_frequency = $(this).data('plan-frequency');

			var planFormKey = fd.plan_preference+'_'+fd.plan_frequency;

			if(mdl.planFormData[planFormKey].couponDetail)
				fd.coupon_detail = mdl.planFormData[planFormKey].couponDetail;

			mdl.onSelectPlan(fd);

			mdl.reset()
		})
	}

	mdl.reset = function() {
		// mdl.planFormData = {
		// 	basic_monthly : {},
		// 	basic_yearly : {},
		// 	pro_monthly : {},
		// 	pro_yearly : {}
		// };
	}

 	/** 
 	 * @override - method to be override by parent modules
 	 */
	mdl.onSelectPlan = function(data) {}

	return mdl;
})();

export default pricingModule;
