import { verifyPromocode } from "@teem-app/js/teemapp/features/teem-helper";
import CommonHelper from "@teem-app/js/teemapp/common-helper";
import * as Api from "@teem-app/js/teemapp/constants/api";
import i18n from "@teem-app/js/teemapp/i18n";
import Swal from "../sweetalert2";

var SignupPayment = (function(CommonHelper) {
    "use strict";

    var mdl = {};
    mdl.urls = {
        register: Api.auth.register,
        verify_promocode: Api.promocode.verify
    };
    mdl.promoCodeValidator = null;
    mdl.subscriptionPlans = null;
    mdl.couponDetail = null;

    mdl.promocodeValidationOptions = {
        rules: {
            coupon_code: "required"
        },
        submitHandler: function(form) {
            var fd = CommonHelper.getFormData(form);
            fd.is_freelance = fd.is_freelance ? true : false;
            fd.subscription_plan = `${mdl.selectors.subTypeSelect.val()}-${mdl.selectors.planTypeSelect.val()}`;

            CommonHelper.showLoading(true);

            verifyPromocode(fd)
                .then(function(couponDetail) {
                    CommonHelper.showLoading(false);

                    mdl.couponDetail = couponDetail;
                    mdl.promoCodeValidator.resetForm();
                    mdl.selectors.promoCodeForm[0].reset();

                    mdl.updateCouponDOMElements();
                    mdl.onChangePlan();
                })
                .catch(function(err) {
                    CommonHelper.showLoading(false);

                    mdl.couponDetail = null;

                    if (err && err.message)
                        mdl.promoCodeValidator.showErrors({
                            coupon_code: err.message
                        });
                    else
                        mdl.promoCodeValidator.showErrors({
                            coupon_code: i18n.t("front_pages.request_failed")
                        });

                    mdl.updateCouponDOMElements();
                    mdl.onChangePlan();
                });
        }
    };

    mdl.updateCouponDOMElements = function() {
        if (mdl.couponDetail) {
            mdl.selectors.promoCodeForm.addClass("d-none");
            mdl.selectors.appliedCouponArea.removeClass("d-none");

            if (mdl.couponDetail.freelance_document_id) {
                mdl.selectors.appliedCouponDetail.addClass("d-none");
                mdl.selectors.appliedCouponFreelance.removeClass("d-none");
            } else {
                mdl.selectors.appliedCouponDetail.removeClass("d-none");
                mdl.selectors.appliedCouponFreelance.addClass("d-none");

                mdl.selectors.appliedCouponCodeText.text(
                    mdl.couponDetail.coupon_code
                );
                mdl.selectors.appliedCouponValueText.text(
                    mdl.couponDetail.coupon_value
                );
                mdl.selectors.appliedCouponValueTypeText.text(
                    i18n.t("front_pages.percentage", "Percentage")
                );
            }
        } else {
            mdl.selectors.promoCodeForm.removeClass("d-none");
            mdl.selectors.appliedCouponArea.addClass("d-none");

            mdl.selectors.appliedCouponCodeText.text("");
            mdl.selectors.appliedCouponValueText.text("");
            mdl.selectors.appliedCouponValueTypeText.text("");
        }
    };

    mdl.init = function(userDetails) {
        mdl.bindSelectors();
        mdl.bindFormValidations();
        mdl.bindListeners();

        // if(userDetails.country_code == 'SA') {
        //  mdl.selectors.planTypeSelect.val('monthly').niceSelect('update');
        //  mdl.selectors.subTypeSelect.val('pro').niceSelect('update');
        // }

        if (
            userDetails.selectedPlan &&
            userDetails.selectedPlan.plan_frequency &&
            userDetails.selectedPlan.plan_preference
        ) {
            mdl.selectors.planTypeSelect
                .val(userDetails.selectedPlan.plan_frequency)
                .niceSelect("update");
            mdl.selectors.subTypeSelect
                .val(userDetails.selectedPlan.plan_preference)
                .niceSelect("update");

            delete userDetails.selectedPlan;
        }

        // initialize user details
        mdl.userDetails = userDetails;

        // initials coupon details
        if (userDetails.coupon_detail) {
            mdl.couponDetail = mdl.userDetails.coupon_detail;

            delete mdl.userDetails.coupon_detail;
            mdl.updateCouponDOMElements();
        }

        mdl.subscriptionPlans = window.subscriptionPlans;
        mdl.onChangePlan();
    };

    /**
     * Register user
     * @param pay # if true then then it will redirect to payfort payment page
     * @param paymentData # This parameter is defined for applepay.
     */
    mdl.registerUser = function(pay, paymentData) {
        CommonHelper.showLoading(true);

        var fd = Object.assign({}, mdl.userDetails);

        fd.plan_preference = mdl.selectors.subTypeSelect.val();

        fd.plan_frequency = mdl.selectors.planTypeSelect.val();

        if (mdl.couponDetail) fd.coupon_detail = mdl.couponDetail;

        if (!fd.email) {
            fd.email = fd.business_email;
        }

        if (!fd.phone) {
            fd.phone = fd.business_phone;
        }

        if (fd.country_id == "other") fd.country_id = null;

        if (pay) {
            fd.pay = true;
        }

        if (paymentData) {
            fd.payment_data = paymentData.payment_type;
        }

        $.ajax({
            url: mdl.urls.register,
            type: "post",
            data: fd,
            success: function(r) {
                console.log("user response ", r);
                CommonHelper.showLoading(false);

                if (r.merchant_ref && r.hasOwnProperty("uuid") && paymentData)  {
                    mdl.doApplePayPayment(r.uuid);
                } else if (pay && r.merchant_ref) {
                    window.location.href = "/process-payment?order_ref=" + r.merchant_ref;
                } else if (r.otp_verify) {
                    window.location.href = r.otp_verify;
                } else {
                    window.location.href = "/verify-email";
                }
            },
            error: function(xhr) {
                CommonHelper.showLoading(false);

                var error = xhr.responseJSON;

                Swal.fire({
                    title: i18n.t("front_pages.warning"),
                    text: error.message
                        ? i18n.t(
                              error.message,
                              i18n.t("front_pages.request_failed")
                          )
                        : i18n.t("front_pages.request_failed"),
                    allowOutsideClick: () => !Swal.isLoading(),
                    type: "warning",
                    icon: "warning",
                    className: "warning",
                    buttons: {
                        catch: {
                            text: i18n.t("front_pages.ok", "Ok"),
                            value: "catch"
                        }
                    }
                });
            }
        });
    };

    mdl.bindSelectors = function() {
        mdl.selectors = {
            planTitleText: $("#signup-payment #selected_plan_title"),
            planBenifitPriceText: $(
                "#signup-payment #selected_plan_amount_per_period"
            ),
            planBenifitNewText: $(
                "#signup-payment #selected_plan_new_amount_per_period"
            ),
            planAmountText: $("#signup-payment #selected_plan_amount"),
            planNewAmountText: $("#signup-payment #selected_plan_new_amount"),

            /* Payment Form Elements */
            subTypeSelect: $("#signup-payment select#subType"),
            planTypeSelect: $("#signup-payment select#planType"),
            startTrailButton: $("#signup-payment button#startFreeSignup"),
            skipAndSignupButton: $("#signup-payment button#skipAndSignup"),
            // cardError: $("#signup-payment #error-handler").addClass("field-error"),
            payForm: $("#signup-payment #payform"),

            /* Coupon DOM Elements */
            promoCodeForm: $("#signup-payment #promocodeForm"),
            freelanceCheckbox: $(
                '#signup-payment input[type="checkbox"]#is_freelance'
            ),
            appliedCouponArea: $(
                "#signup-payment .account-setting__plan--promo-apply"
            ),
            appliedCouponDetail: $("#signup-payment #coupon-detail"),
            appliedCouponFreelance: $("#signup-payment #coupon-freelance"),
            appliedCouponCodeText: $("#signup-payment #applied_coupon_code"),
            appliedCouponValueText: $("#signup-payment #applied_coupon_value"),
            appliedCouponValueTypeText: $(
                "#signup-payment #applied_coupon_value_type"
            ),
            cancelCouponUpdate: $("#signup-payment .cancel-payment-update")
        };
    };

    mdl.bindFormValidations = function() {
        mdl.promoCodeValidator = mdl.selectors.promoCodeForm.validate(
            mdl.promocodeValidationOptions
        );
    };

    mdl.bindListeners = function() {
        mdl.selectors.subTypeSelect.change(function(e) {
            mdl.couponDetail = null;
            mdl.updateCouponDOMElements();
            mdl.onChangePlan(mdl.selectors.planTypeSelect.val(), $(this).val());
        });

        mdl.selectors.planTypeSelect.change(function(e) {
            mdl.couponDetail = null;
            mdl.updateCouponDOMElements();

            mdl.onChangePlan($(this).val(), mdl.selectors.subTypeSelect.val());
        });

        mdl.selectors.startTrailButton.click(function() {
            mdl.registerUser(true);
        });

        mdl.selectors.skipAndSignupButton.click(function() {
            mdl.registerUser();
        });

        mdl.selectors.cancelCouponUpdate.click(function() {
            mdl.couponDetail = null;
            mdl.updateCouponDOMElements();

            mdl.promoCodeValidator.resetForm();
            mdl.selectors.promoCodeForm[0].reset();

            mdl.onChangePlan();
        });
    };


    mdl.onChangePlan = function(plantype, subtype) {
        if (!plantype) plantype = mdl.selectors.planTypeSelect.val();

        if (!subtype) subtype = mdl.selectors.subTypeSelect.val();

        var key = `${plantype}_${subtype}`;
        var selectedPlan = mdl.subscriptionPlans[key];

        /* Selected Plan Schema
         * '[
         *           'plan' => 'monthly|yearly',
         *           'type' => 'basic|pro',
         *           'amount' => '',
         *           'translations' => [
         *               'title' => '',
         *               'amount_per_period' => '',
         *               'plan' => '',
         *               'type' => ''
         *           ]
         *       ]
         */

        mdl.selectors.planTitleText.text(selectedPlan.translations.title);
        mdl.selectors.planBenifitPriceText
            .removeClass("old-price")
            .text(
                selectedPlan.translations.amount_per_period.replace(
                    "__amount__",
                    selectedPlan.amount
                )
            );
        mdl.selectors.planBenifitNewText.addClass("d-none").text("");
        mdl.selectors.planAmountText.text(selectedPlan.amount);

        // Update plan amount as per coupon
        if (mdl.couponDetail) {
            if (mdl.couponDetail.discount_message) {
                mdl.selectors.planBenifitPriceText.addClass("old-price");
                mdl.selectors.planBenifitNewText
                    .removeClass("d-none")
                    .text(mdl.couponDetail.discount_message);

                if (mdl.couponDetail.discount_amount)
                    mdl.selectors.planAmountText.text(
                        mdl.couponDetail.discount_amount
                    );
            }
        }
    };

    mdl.getSelectedPlan = function() {
        var plantype = mdl.selectors.planTypeSelect.val();
        var subtype = mdl.selectors.subTypeSelect.val();
        return mdl.subscriptionPlans[`${plantype}_${subtype}`];
    };

    return mdl;
})(CommonHelper);

export default SignupPayment;
