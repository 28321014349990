import Swal from './sweetalert2';
import i18n from  './i18n';

/**
 * Common Helper functions
 */
var CommonHelper = (function() {
	'use strict';

	var mdl = {};

	mdl.selectors = {
		loaderScreen : $('#loaderScreen')
	}

	/**
	 * Show loading screen
	 * @param boolean value true|false 
	 */
	mdl.showLoading = function(value) {
		if(value)
			mdl.selectors.loaderScreen.removeClass('d-none')
		else
			mdl.selectors.loaderScreen.addClass('d-none')
	}

	mdl.showError = function(obj) {
		if(obj.responseJSON) {
			var err = obj.responseJSON;

			let msg = err && err.message ? err.message : (err.error ? err.error : i18n.t('front_pages.request_failed'));
      
      Swal.fire(i18n.t('front_pages.error'), msg, 'error');	
		}
	}

	mdl.getFormData = function(form) {
		var unindexed_array = $(form).serializeArray();
		var indexed_array = {};

		$.map(unindexed_array, function(n, i){
			if (indexed_array[n['name']]) {
          if (!indexed_array[n['name']].push) {
              indexed_array[n['name']] = [indexed_array[n['name']]];
          }
          indexed_array[n['name']].push(n['value'] || '');
      } else {
        indexed_array[n['name']] = n['value'] || '';
      }
    });

    return indexed_array;
	}

	return mdl;
})();

export default CommonHelper;