import CommonHelper from '../common-helper';
import STCPayHelper from '../helpers/stcpay';
import i18n from '../i18n';
import Swal from '../sweetalert2';

$(function() {
	var submitRequestForm = function() {
		CommonHelper.showLoading(true);

		$('button[type="submit"]').prop('disabled', true);

		STCPayHelper.sendPaymentRequest(
			$('#paymentRequestForm').serialize()
		).then(function(response) {
			console.log(response);

			$('#paymentRequestForm').addClass('d-none');
			$('#stcPaymentModal .modal-bottom').removeClass('d-none');

			// Set payment confirm form parameters
			$('#paymentConfirmForm input[name="OtpReference"]').val(response.OtpReference);
			$('#paymentConfirmForm input[name="STCPayPmtReference"]').val(response.STCPayPmtReference)
			$('#paymentConfirmForm #OTPmobileNo').text(response.mobileNo);
			
      
			$('#paymentConfirmForm').removeClass('d-none');
		}, function(error) {
			CommonHelper.showLoading(false);

			Swal.fire({
        title: i18n.t("front_pages.error"),
        text: error.message ? error.message : i18n.t('front_pages.request_failed'), //i18n.t(error.message, i18n.t('front_pages.request_failed')) : i18n.t('front_pages.request_failed'),
        allowOutsideClick: () => !Swal.isLoading(),
        icon: "error",
        confirmButtonText: i18n.t("front_pages.ok", "Ok")
      });
		}).finally(function() {
			CommonHelper.showLoading(false);
			$('button[type="submit"]').prop('disabled', false);
		})
	}
	
	$('#paymentRequestForm').submit(function(e) {
		e.preventDefault();

		submitRequestForm();
	});

	$('#paymentConfirmForm').submit(function(e) {
		e.preventDefault();

		CommonHelper.showLoading(true);

		STCPayHelper.confirmPaymentRequest(
			$(this).serialize()
		).then(function(response) {
			console.log(response);

			// $('#paymentConfirmForm').addClass('d-none');

			Swal.fire(
				i18n.t('front_pages.done'), 
				i18n.t('front_pages.stcpay.payment_captured', 'Payment Captured Successfully!'),
				'success').then(function() {
				window.location.href = baseUrl+'/invoices';
			});
		}, function(error) {
			console.log(error);
			Swal.fire({
        title: i18n.t("front_pages.error"),
        text: error.message ? error.message : i18n.t('front_pages.request_failed'), //i18n.t(error.message, i18n.t('front_pages.request_failed')) : i18n.t('front_pages.request_failed'),
        allowOutsideClick: () => !Swal.isLoading(),
        icon: "error",
        confirmButtonText: i18n.t("front_pages.ok", "Ok")
      });
			
			$('#paymentRequestForm').removeClass('d-none');
			$('#paymentConfirmForm').addClass('d-none');
			$('#stcPaymentModal .modal-bottom').addClass('d-none');
		}).finally(function() {
			CommonHelper.showLoading(false);
		})
	});

	$('#resendOtp').click(function() {
		submitRequestForm();
	});
});