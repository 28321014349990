export const shop = {
	getCountries: `${baseUrl}/countries`,
	getCities: `${baseUrl}/cities`,
	// storeOrder: (uuid) => `${baseUrl}/api/shop/${uuid}/orders`,
	storeOrder: `${baseUrl}/api/shop/orders`,
	getProductDetails: (uuid) => `${baseUrl}/api/product/${uuid}`,
	getServiceDetails: (uuid) => `${baseUrl}/api/service/${uuid}`,
	getCart: (uuid) => `${baseUrl}/api/shop/${uuid}/cart`,
	addItem: (uuid) => `${baseUrl}/api/shop/${uuid}/cart-items`,
	deleteItem: (uuid, itemId) => `${baseUrl}/api/shop/${uuid}/cart-items/${itemId}`,
	deleteItemFromCart: (uuid, itemId) => `${baseUrl}/api/shop/${uuid}/remove-cart-items/${itemId}`,
	getDistrict: (cityId) => `${baseUrl}/districts`,
	checkOTODeliveryFee: `${baseUrl}/api/shop/check-oto-delivery`,
	checkValidPromocode: (uuid) => `${baseUrl}/api/shop/${uuid}/check-valid-promocode`,
	validateDeliveryTime: (uuid) => `${baseUrl}/api/shop/${uuid}/validate-delivery-time`,
	getCartRoute: (uuid) => `${baseUrl}/get-cart-view/${uuid}`,
	removeValidPromocode: `${baseUrl}/api/shop/remove-valid-promocode`,
}

export const order = {
	doAction: (uuid) => `${baseUrl}/api/orders/${uuid}/action`,
}

export const otp = {
	sendOtpPin: `${baseUrl}/api/shop/send-otp`,
	verifyOtpPin: `${baseUrl}/api/shop/verify-otp`,
	resendOtpPin: `${baseUrl}/api/shop/resend-otp`,
}

export const careem = {
	cities: {18:'JEDDAH', 3:'RIYADH'},
}