import CommonHelper from '../common-helper';
import Swal from '../sweetalert2';
import i18n from '../i18n';
import  * as Api from '../constants/api';

var ResetPasswordModule = (function(CommonHelper) {
	var mdl = {};

	mdl.urls = {
		'reset_password': Api.auth.reset_password
	}

	mdl.resetPasswordValidationOptions = {
		errorPlacement: function(error, element) {
  		$(element).closest('fieldset').find('.field-error').text(error.text());
  	},
		rules: {
			password: {
        required: true,
        minlength: 6
      },
      password_confirmation: {
        required: true,
        minlength: 6,
        equalTo: "#new_password"
      }
		},
		submitHandler: function(form) {
			
			$.ajax({
				url: mdl.urls.reset_password,
				type: 'post',
				data: CommonHelper.getFormData(form),
				beforeSend: function() {
					CommonHelper.showLoading(true);
				},
				success: function(r) {
					Swal.fire(i18n.t('front_pages.done'), r.message, 'success').then(function() {
						window.location.href = baseUrl;
					})
				},
				error: function(xhr) {
					if(xhr.status == 422) {
						mdl.resetPasswordValidator.showErrors(xhr.responseJSON.errors);
					}
				}
			}).always(function(data, textStatus, jqXHR) { 
			    CommonHelper.showLoading(false);
			})

			return false;
		}
	};

	mdl.init = function() {
		mdl.bindSelectors();
		mdl.bindFormValidations();
	}

	mdl.bindFormValidations = function() {
		mdl.resetPasswordValidator = mdl.selectors.resetForm.validate(mdl.resetPasswordValidationOptions)
	}

	mdl.bindSelectors = function() {
		mdl.selectors	= {
			resetForm: $('#resetForm') 
		}
	}
	
	return mdl;
})(CommonHelper);

export default ResetPasswordModule;