import CommonHelper from '../common-helper';
import i18n from  '../i18n';
import  * as Api from '../constants/api';

var HomeSignupModule = (function(commonHelper) {
	var mdl = {};
	mdl.urls ={
		unique_user_email: Api.user.unique_email,
	}

	mdl.defaultValidationOptions = {
		ignore:[],
		unhighlight: function(element) {
			$(element).closest('fieldset').removeClass("has-error");
			$(element).siblings('.field-status').removeClass('in-valid');
		},
  	errorPlacement: function(error, element) {
			$(element).siblings('.field-status').removeClass('valid').addClass('in-valid');
  		$(element).siblings('.field-error').text(error.text());
  	},
		success: function(error, element) {
			$(element).siblings('.field-status').removeClass('in-valid').addClass('valid');
    }
	}

	mdl.homeSignupValidationOptions = {
		rules: {
			name: {
				required : true,
				regex:/^[a-zA-Z ]*$/
			},
			email: {
				'required': true, 
				'email': true,
				'remote': {
					url: mdl.urls.unique_user_email,
					dataFilter: function(response) {
						let data = JSON.parse(response);
						return !data.is_exist;
					}
				}
			}
		},
		messages: {
			email : {
				'remote': i18n.t('front_pages.sign_up_page.validation.email_exist') 
			}
		},
    submitHandler: function(form) {
			let fd = commonHelper.getFormData(form);
			
			mdl.onSubmit(fd)
			
	    return false;
	  }
	};
 	
 	/** 
 	 * @override - method to be override by parent modules
 	 */
	mdl.onSubmit = function(fd) {}
	
	mdl.init = function() {
		mdl.bindSelectors();
		mdl.bindFormValidation();
	}

	mdl.bindSelectors = function() {
		mdl.selectors = {
			homeSignupForm : $('#home-signup-form')
		}
	}

	mdl.bindFormValidation = function() {
		mdl.homeSignupValidator = mdl.selectors.homeSignupForm.validate(Object.assign(mdl.defaultValidationOptions, mdl.homeSignupValidationOptions));
	}

	return mdl;
})(CommonHelper)

export default HomeSignupModule;