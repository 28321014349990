import CommonHelper from '../common-helper';
import Swal from '../sweetalert2';
import i18n from '../i18n';
import * as Api from '../constants/api';
import mixpanel from 'mixpanel-browser';

var OtpModule = (function (CommonHelper) {
	var mdl = {};

	mdl.urls = {
		verify_opt: Api.otp.verify,
		resend_opt: Api.otp.resend
	}

	mdl.verifyOtpValidationOptions = {
		errorPlacement: function (error, element) {
			$(element).closest('fieldset').find('.field-error').text(error.text());
		},
		rules: {
			number_1: {
				required: true,
				maxlength: 1,
				number: true
			},
			number_2: {
				required: true,
				maxlength: 1,
				number: true
			},
			number_3: {
				required: true,
				maxlength: 1,
				number: true
			},
			number_4: {
				required: true,
				maxlength: 1,
				number: true
			},
			number_5: {
				required: true,
				maxlength: 1,
				number: true
			},
			number_6: {
				required: true,
				maxlength: 1,
				number: true
			},
		},
		submitHandler: function (form) {
			// event.preventDefault();
			console.log(event);
			let data = CommonHelper.getFormData(form);
			let new_otp = data.number_1 + data.number_2 + data.number_3 + data.number_4 + data.number_5 + data.number_6;
			console.log(new_otp);
			$.ajax({
				url: mdl.urls.verify_opt,
				type: 'post',
				data: {
					'otp': new_otp,
					'uuid': $('#uuid').val()
				},
				beforeSend: function () {
					CommonHelper.showLoading(true);
				},
				success: function (r) {
					Swal.fire(i18n.t('front_pages.done'), r.message, 'success').then(function () {
						let type = $('.number').attr('data-type');
						mixpanel.init(MixpanelToken);
						if (type == 'phone') {
							mixpanel.track('auth_otp_phone_success');
						} else {
							mixpanel.track('auth_otp_email_success');
						}
						
                        // window.location.href = baseUrl;
                        window.location.href = baseUrl+'/signup-thanks';
					})
				},
				error: function (xhr, textStatus, errorThrown) {
					console.log(xhr, textStatus, errorThrown);
					if (xhr.status == 403) {
						Swal.fire(i18n.t('front_pages.error'), xhr.responseJSON.message, 'error');
						mdl.otpSendValidator.showErrors(xhr.responseJSON.errors);
					}
				}
			}).always(function (data, textStatus, jqXHR) {
				CommonHelper.showLoading(false);
			})

			return false;
		}
	};

	mdl.init = function () {
		let type = $('.number').attr('data-type');
		mixpanel.init(MixpanelToken);
		if (type == 'phone') {
			mixpanel.track('auth_otp_phone_sent');
		} else {
			mixpanel.track('auth_otp_email_sent');
		}
		
		mdl.bindSelectors();
		mdl.bindFormValidations();
		mdl.resendOtp();
		mdl.numberInputValidation();
	}

	mdl.numberInputValidation = function () {
		$(document).on('input', '.opt-num', function (e) {
			this.value = this.value.replace(/[^0-9]/g, '');
			if (this.value.length == 1) {
				$(this).parent().next().find('input').focus();
			}
		});
	}

	mdl.resendOtp = function () {
		$('#resendCode').click(function (e) {
			e.preventDefault();
			$.ajax({
				url: mdl.urls.resend_opt,
				type: 'post',
				data: {
					uuid: $('#uuid').val()
				},
				beforeSend: function () {
					CommonHelper.showLoading(true);
				},
				success: function (r) {
					Swal.fire(i18n.t('front_pages.done'), r.message, 'success').then(function () {
						// window.location.href = baseUrl;
					})
				},
				error: function (xhr) {
					console.log(xhr);
				}
			}).always(function (data, textStatus, jqXHR) {
				CommonHelper.showLoading(false);
			});
		});
	}

	mdl.bindFormValidations = function () {
		mdl.otpSendValidator = mdl.selectors.otpSendForm.validate(mdl.verifyOtpValidationOptions)
	}

	mdl.bindSelectors = function () {
		mdl.selectors = {
			otpSendForm: $('#otpSendForm')
		}
	}

	return mdl;
})(CommonHelper);

export default OtpModule;
