import './app-setting';

import SigninModule from './features/signin';
import SignupModule from './features/signup';
import ResetPasswordModule from './features/reset-password';
import OtpModule from './features/otp';

import './features/stcpay-payment';

window.SigninModule = SigninModule;

$(function() {
	SigninModule.init();

	if($('#signup-wizard').length) {
		SignupModule.init();
	}

	if($('#resetForm').length) {
		ResetPasswordModule.init();
	}

	if($('#otpSendForm').length) {
		OtpModule.init();
	}

});
