/**
 * Language bundle
 */
import i18next from 'i18next'
import languageBundle from '@kirschbaum-development/laravel-translations-loader?namespace=translation&parameters={{ $1 }}!@kirschbaum-development/laravel-translations-loader';

i18next.init({
    lng: window.Locale,
    fallbackLng: 'en',
    resources: languageBundle
});

export default i18next;