import CommonHelper from '../common-helper';
import Swal from '../sweetalert2';
import i18n from  '../i18n';
import  * as Api from '../constants/api';
import mixpanel from 'mixpanel-browser';

/**
 * Signin module
 */
var SigninModule = (function(CommonHelper) {
	'use strict';
	
	var mdl = {};

	mdl.urls = {
		login : Api.auth.signin,
		forgotPassword: Api.auth.forgot_password,
		sendVerification: Api.auth.send_verification
	}
	mdl.signinValidator = null;
	mdl.forgotPasswordValidator = null;
	mdl.verifyAccountValidator = null;

	/* Signin Form Validator */
	mdl.signinValidationOptions = {
		submitHandler: function(form) {
			$.ajax({
				url: mdl.urls.login,
				type: 'post',
				data: $(form).serialize(),
				beforeSend: function() {
					CommonHelper.showLoading(true);
				},
				success: function(r) {
					window.location.reload();
				},
				error: function(xhr) {
					if(xhr.status == 422) {
						mdl.signinValidator.showErrors(xhr.responseJSON.errors);
					} else {
						CommonHelper.showError(xhr);
					}
				} 
	    }).always(function(data, textStatus, jqXHR) { 
			    CommonHelper.showLoading(false);
			})
	    return false;
	  }
	};

	/* Forgot password Form Validator */
	mdl.forgotPasswordValidationOptions = {
		rules: {
			'email': 'email'
		},
		submitHandler: function(form) {
			$.ajax({
				url: mdl.urls.forgotPassword,
				type: 'post',
				data: $(form).serialize(),
				beforeSend: function() {
					CommonHelper.showLoading(true);
				},
				success: function(r) {
					Swal.fire(i18n.t('front_pages.done'), r.message, 'success').then(function() {
						window.location.href = baseUrl;
					});
				},
				error: function(xhr) {
					if(xhr.status == 422) {
						mdl.forgotPasswordValidator.showErrors(xhr.responseJSON.errors);
					} else {
						CommonHelper.showError(xhr);	
					}
					
				} 
	    }).always(function(data, textStatus, jqXHR) { 
			    CommonHelper.showLoading(false);
			})
	    return false;
	  }
	};

	/* Verify account Form Validator */
	mdl.verifyAccountValidationOptions = {
		rules: {
			'email': 'email'
		},
		submitHandler: function(form) {
			$.ajax({
				url: mdl.urls.sendVerification,
				type: 'post',
				data: $(form).serialize(),
				beforeSend: function() {
					CommonHelper.showLoading(true);
				},
				success: function(r) {
					Swal.fire(i18n.t('front_pages.done'), r.message, 'success').then(function() {
						window.location.href = baseUrl;
					});
				},
				error: function(xhr) {
					if(xhr.status == 422) {
						mdl.verifyAccountValidator.showErrors(xhr.responseJSON.errors);
					} else {
						CommonHelper.showError(xhr);	
					}
					
				} 
	    }).always(function(data, textStatus, jqXHR) { 
			    CommonHelper.showLoading(false);
			})
	    return false;
	  }
	};

	/**
	 * Initialize module
	 */ 
	mdl.init = function () {
		this.bindSelectors();
		this.bindListeners();
		this.bindFormValidations();

		$('input', mdl.selectors.signinForm).attr('autocomplete', 'off');
	}
  
	mdl.bindSelectors = function() {
		mdl.selectors = {
			signinLink:  $('#signin-link'),
			signinForm:  $('#signin-form'),
			verifyAccountLink:  $('#verify-account-link'),
			verifyAccountForm:  $('#verify-account-form'),
			verifyAccountContent: $('#verify-account-content'),
			forgotPasswordLink:  $('#forgot-password-link'),
			forgotPasswordForm:  $('#forgot-password-form'),
			signinContent: $('#signin-content'),
			forgotPasswordContent: $('#forgot-password-content'),
			signinModal: $('#signIn')
		};
	}

	/**
	 * Bind events on selectors
	 * @param boolean value true|false 
	 */
	mdl.bindFormValidations = function() {
		mdl.signinValidator = mdl.selectors.signinForm.validate(mdl.signinValidationOptions);
		mdl.forgotPasswordValidator = mdl.selectors.forgotPasswordForm.validate(mdl.forgotPasswordValidationOptions);
		mdl.verifyAccountValidator = mdl.selectors.verifyAccountForm.validate(mdl.verifyAccountValidationOptions);
	}

	/**
	 * Bind events on selectors
	 * @param boolean value true|false 
	 */
	mdl.bindListeners = function() {

		$('#email').blur(function () {
			mixpanel.init(MixpanelToken);
			mixpanel.track('auth_signin_start');
		});
	
		mdl.selectors.verifyAccountLink.click(function () {
			mdl.toggleSigninAndAccountVerify(true);
		});

		mdl.selectors.forgotPasswordLink.click(function () {
			mdl.toggleSigninAndForgotPassword(true);
			//   mdl.toggleSigninAndAccountVerify(false);
		});

		mdl.selectors.signinLink.click(function () {
			mdl.toggleSigninAndForgotPassword(false);
			// mdl.toggleSigninAndAccountVerify(false);
		});

		mdl.selectors.signinModal.on('hide.bs.modal', function(){
			mdl.signinValidator.resetForm();
			mdl.forgotPasswordValidator.resetForm();
			mdl.verifyAccountValidator.resetForm();
			mdl.toggleSigninAndForgotPassword(false);
		});
  	}

	/**
	 * Toggle "Signin" and "Forgot password" form
	 * @param boolean value true|false 
 	 */
	mdl.toggleSigninAndForgotPassword = function(value) {
		if(value) {
			mdl.signinValidator.resetForm();

			// Show forgot password form
			mdl.selectors.signinContent.addClass('d-none');
			mdl.selectors.verifyAccountContent.addClass('d-none');
			mdl.selectors.forgotPasswordContent.removeClass('d-none');

			
		} else {
			mdl.forgotPasswordValidator.resetForm();
			mdl.signinValidator.resetForm();
			
			// Show signin form
			mdl.selectors.signinContent.removeClass('d-none');
			mdl.selectors.forgotPasswordContent.addClass('d-none');
			mdl.selectors.verifyAccountContent.addClass('d-none');
		}
	}

	/**
	 * Toggle "Signin" and "Forgot password" form
	 * @param boolean value true|false 
 	 */
	 mdl.toggleSigninAndAccountVerify = function(value) {
		if(value) {
			mdl.verifyAccountValidator.resetForm();
			
			// Show forgot password form
			mdl.selectors.signinContent.addClass('d-none');
			mdl.selectors.forgotPasswordContent.addClass('d-none');
			mdl.selectors.verifyAccountContent.removeClass('d-none');

			
		} else {
			mdl.signinValidator.resetForm();
			
			// Show signin form
			mdl.selectors.signinContent.removeClass('d-none');
			mdl.selectors.forgotPasswordContent.addClass('d-none');
			mdl.selectors.verifyAccountContent.addClass('d-none');
		}
	}


  return mdl;
})(CommonHelper);


export default SigninModule;