import CommonHelper from '../common-helper';
import i18n from  '../i18n';
import  * as Api from '../constants/api';
import { verifyPromocode } from './teem-helper';

var HomeSignupModule = (function(commonHelper) {
	'use strict';

	var mdl = {};
	mdl.urls ={
		unique_user_email: Api.user.unique_email,
		unique_user_phone: Api.user.unique_phone,
		verify_promocode: Api.promocode.verify,
	}

	mdl.homeSignupValidator = null;

	mdl.defaultValidationOptions = {
		ignore:[],
		unhighlight: function(element) {
			$(element).closest('fieldset').removeClass("has-error");
			$(element).siblings('.field-status').removeClass('in-valid');
		},
  	errorPlacement: function(error, element) {
			$(element).siblings('.field-status').removeClass('valid').addClass('in-valid');
  		$(element).siblings('.field-error').text(error.text());
  	},
		success: function(error, element) {
			$(element).siblings('.field-status').removeClass('in-valid').addClass('valid');
    }
	}
	
	mdl.homeSignupValidationOptions = {
		rules: {
			name: 'required',
			phone: {
				required: true,
				number: true,
				'remote': {
					url: mdl.urls.unique_user_phone,
					dataFilter: function(response) {
						var data = JSON.parse(response);
						return !data.is_exist;
					}
				}
			},
			coupon_code: {
				'remote': {
					url: mdl.urls.verify_promocode,
					data: {
						is_freelance: true,
						subscription_plan: ""
					},
					beforeSend: function() {
						$('input[name="coupon_code"]').siblings('.field-status').hide();
						$('input[name="coupon_code"]').siblings('.state-loading').removeClass('d-none');
					},
					dataFilter: function(response) {
						let data = JSON.parse(response);
						// if(data.status == 'valid')
						// 	mdl.tempCouponData	= data;
						return data.status == 'valid';
					},
					complete: function() {
						$('input[name="coupon_code"]').siblings('.field-status').show();
						$('input[name="coupon_code"]').siblings('.state-loading').addClass('d-none');
					}
				}
			},
			email: {
				'required': true, 
				'email': true,
				'remote': {
					url: mdl.urls.unique_user_email,
					dataFilter: function(response) {
						let data = JSON.parse(response);
						return !data.is_exist;
					}
				}
			}
		},
		messages: {
			email : {
				'remote': i18n.t('front_pages.sign_up_page.validation.email_exist') 
			},
			phone: {
				'remote': i18n.t('front_pages.sign_up_page.validation.phone_exist') 
			} 
		},
    submitHandler: function(form) {
			let fd = commonHelper.getFormData(form);
			
			if(fd.coupon_code) {
				
				verifyPromocode({coupon_code: fd.coupon_code}).then(function(promoCode) {
					fd.coupon_detail = promoCode;

					mdl.onSubmit(fd);
				}).catch(function(err) {
					
					if(err && err.message)
						mdl.homeSignupValidator.showErrors({'coupon_code': err.message});
					else
						mdl.homeSignupValidator.showErrors({'coupon_code': i18n.t('front_pages.request_failed')});
				})
			} else {
				mdl.onSubmit(fd);
			}

	    return false;
	  }
	};

 	/**
 	 * @override - method to be override by parent modules
 	 */
	mdl.onSubmit = function(fd) {}

	mdl.init = function(onComplete) {
		mdl.bindSelectors();
		mdl.bindFormValidation();
	}

	mdl.bindSelectors = function() {
		mdl.selectors = {
			homeSignupForm : $('#home-signup-form')
		}
	}

	mdl.bindFormValidation = function() {
		mdl.homeSignupValidator = mdl.selectors.homeSignupForm.validate(Object.assign(mdl.defaultValidationOptions, mdl.homeSignupValidationOptions));
	}

	return mdl;
})(CommonHelper)

export default HomeSignupModule;