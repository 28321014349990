import i18n from  '../i18n';
import  * as Api from '../constants/api';

export function verifyPromocode(fd) {
	return new Promise( function(resolve, reject) {

		if(!fd.coupon_code) {
			console.warning('Can\'t call promocode api without coupon code')
			return;
		}

		if(fd.is_freelance == undefined)
			fd.is_freelance = true;

		if(fd.subscription_plan == undefined)
			fd.subscription_plan = ""
		
		$.ajax({
			url: Api.promocode.verify,
			type: 'get',
			data: fd,
			success: function(r) {
				if(r.status == 'valid' && r.validPromocode) {
					var couponDetail = {
						'coupon_code': r.validPromocode.coupon_code,
						'coupon_value': r.validPromocode.coupon_value,
						'coupon_type': r.validPromocode.coupon_type,
						'freelance_document_id': r.validPromocode.freelance_document_id,
						'discount_message': r.validPromocode.discount_message,
						'discount_amount': r.validPromocode.discount_amount
					}
					resolve(couponDetail)
				} if(r.status == 'invalid' && r.msg) {
					reject({message: r.msg});
				}
				else 
					reject({message: i18n.t('account_settings.messages.invalid_code')});
			},
			error: function(xhr) {
				reject(xhr.responseJSON);
			}
		});
	});	
}

//export default RegistrationHelper;
