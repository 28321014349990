var STCPayHelper = (function() {
	var URLS = {
		"payment_request": baseUrl+"/api/v1/stc-pay/payment-request",
		"confirm_payment": baseUrl+"/api/v1/stc-pay/payment-confirm"
	}

	var sendPaymentRequest = function(payload) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: URLS.payment_request,
				method: "post",
				dataType: 'json',
				data: payload,
				success: function(r) {
					resolve(r);
				},
				error: function(xhr) {
					reject(xhr.responseJSON);
				}
			})
		});
	}

	var confirmPaymentRequest = function(payload) {
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: URLS.confirm_payment,
				method: "post",
				dataType: 'json',
				data: payload,
				success: function(r) {
					resolve(r);
				},
				error: function(xhr) {
					reject(xhr.responseJSON);
				}
			})
		});
	}

	return {
		sendPaymentRequest: sendPaymentRequest,
		confirmPaymentRequest: confirmPaymentRequest
	}
})();

export default STCPayHelper;