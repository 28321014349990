require('select2');

import i18n from './i18n';


(function () {
  /**
   * Default ajax setting
   */
  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $("meta[name='csrf-token']").attr('content'),
    },
    dataType: "json"
  });

  /**
   * Default vaidation options
   */
  $.validator.setDefaults({
    highlight: function (element) {
      $(element).closest('fieldset').addClass("has-error");
    },
    unhighlight: function (element) {
      $(element).closest('fieldset').removeClass("has-error");
    },
    errorPlacement: function (error, element) {
      $(element).siblings('.field-error').text(error.text());
    },
    messages: {
      'name': {
        'regex': i18n.t('validation.custom.name.regex'),
      },
      'email': {
        'required': i18n.t('validation.custom.email.required'),
        'email': i18n.t('validation.custom.email.valid_email'),
      },
      'password': {
        'required': i18n.t('validation.custom.password.required'),
      },
      'password_confirmation': {
        'required': i18n.t('validation.custom.password_confirmation.required'),
        'equalTo': i18n.t('validation.custom.password_confirmation.same')
      }
    }
  })

  $.validator.addMethod(
    "regex",
    function (value, element, regexp) {
      if (regexp.constructor != RegExp)
        regexp = new RegExp(regexp);
      else if (regexp.global)
        regexp.lastIndex = 0;
      return this.optional(element) || regexp.test(value);
    },
  );

  $.extend($.validator.messages, {
    'required': i18n.t('validation.required'),
    'minlength': i18n.t('validation.minlength', { number: "{0}" }),
    'regex': i18n.t('validation.regex')
  });

})();